<template>
  <section>
    <!-- <Cart v-if="modal == 'cart'" @update-modal="updateModal" /> -->
    <div class="product-section" id="top">
      <!-- BULLET POINTS DESKTOP -->
      <div class="bullet-points desktop">
        <div>
          <ul>
            <li v-if="produto.type">
              {{ content.product.bullet_points1.type
              }}<span>{{ produto.type }}</span>
            </li>
            <li v-if="produto.sub_region">
              {{ content.product.bullet_points1.year }}
              <span>{{ produto.year }}</span>
            </li>
            <li v-if="produto.year">
              {{ content.product.bullet_points1.region
              }}<span>{{ produto.master_region }}</span>
            </li>
            <li v-if="produto.sub_region">
              {{ content.product.bullet_points1.sub_region
              }}<span>{{ produto.sub_region }}</span>
            </li>
            <li v-if="produto.varieties">
              {{ content.product.bullet_points1.varieties
              }}<span>{{ produto.varieties }}</span>
            </li>
          </ul>
          <ul class="secondary-list" id="secondary-list">
            <li v-if="produto.winery">
              {{ content.product.bullet_points2.winery
              }}<span>{{ produto.winery }}</span>
            </li>
            <li v-if="produto.oenologist">
              {{ content.product.bullet_points2.oenologist
              }}<span>{{ produto.oenologist }}</span>
            </li>
            <li v-if="produto.production_limit > 0">
              {{ content.product.bullet_points2.production_limit
              }}<span
                >{{ product_limit }}
                {{ content.product.bullet_points2.production_limit2 }}</span
              >
            </li>
            <li v-if="produto.awards">
              {{ content.product.bullet_points2.awards
              }}<span>{{ produto.awards }}</span>
            </li>
            <li v-if="produto.alcohol">
              {{ content.product.bullet_points2.alcohol
              }}<span>{{ produto.alcohol }}%</span>
            </li>
            <li v-if="produto.allergens">
              {{ content.product.bullet_points2.allergens
              }}<span>{{ produto.allergens }}</span>
            </li>
            <li v-if="produto.temperature">
              {{ content.product.bullet_points2.temperature
              }}<span>{{ produto.temperature }}</span>
            </li>
            <li v-if="produto.food">
              {{ content.product.bullet_points2.food
              }}<span>{{ produto.food }}</span>
            </li>
          </ul>
          <a @click="openList()" id="moreless"
            ><span class="icon-icon-plus"></span
            ><span id="moreless_content">{{ content.product.btn2 }}</span></a
          >
          <router-link to="/online_store" class="back button-fill">{{
            content.product.btn1
          }}</router-link>
        </div>
      </div>
      <div
        class="image desktop"
        :style="{
          backgroundImage:
            'url(' +
            img_url +
            encodeURIComponent(produto.img2).replace('%2F', '/') +
            ')',
        }"
      ></div>
      <!-- END BULLET POINTS DESKTOP -->

      <!-- DESCRIPTION MOBILE -->
      <div class="description mobile">
        <h4>{{ produto.name }}</h4>
        <div class="wine-img"><img :src="img_url + produto.img" /></div>
        <div class="left">
          <!-- PRICE BOTTLE -->
          <!-- W/ DISCOUNT -->
          <p class="price" v-if="produto.discount > 0">
            <span class="icomoon icon-icon-bottle"></span>
            <span class="label">{{ content.product.price }} </span>
            <span class="lt">({{ produto.it }} lt) </span>
            <span class="underline"
              >{{ produto.price.toFixed(2) }}
              <span class="units"> CHF</span></span
            >
            {{ (produto.price * (1 - produto.discount)).toFixed(2) }}
            <span class="units"> CHF</span>
          </p>
          <!-- END W/ DISCOUNT -->

          <!-- W/o DISCOUNT -->
          <p class="price" v-else>
            <span class="icomoon icon-icon-bottle"></span>
            <span class="label">{{ content.product.price }} </span>
            <span class="lt">({{ produto.it }} lt) </span>
            {{ produto.price.toFixed(2) }}
            <span class="units"> CHF</span>
          </p>
          <!-- END W/o DISCOUNT -->
          <!-- END PRICE BOTTLE -->

          <!-- PRICE BOX -->
          <div class="units" v-if="produto.box_quantity > 1">
            <!-- W/ DISCOUNT -->
            <p class="price2" v-if="produto.discount > 0">
              <span class="icomoon icon-icon-box"></span>
              {{ content.product.price2 }}
              <span class="lt"
                >({{ produto.box_quantity }} x {{ produto.it }} lt)
              </span>
              <span class="underline"
                >{{ (produto.price * produto.box_quantity).toFixed(2) }}
                <span class="units">CHF</span></span
              >
              {{
                (
                  produto.price *
                  produto.box_quantity *
                  (1 - produto.discount)
                ).toFixed(2)
              }}
              <span class="units"> CHF</span>
            </p>
            <!-- END W/ DISCOUNT -->

            <!-- W/o DISCOUNT -->
            <p class="price2" v-else>
              <span class="icomoon icon-icon-box"></span>
              {{ content.product.price2 }}
              <span class="lt"
                >({{ produto.box_quantity }} x {{ produto.it }} lt)
              </span>
              {{ (produto.price * produto.box_quantity).toFixed(2) }}
              <span class="units">CHF</span>
            </p>
            <!-- END W/o DISCOUNT -->

            <!-- <span class="dash"></span>
          <p class="order">
            {{ content.product.min_order }}
            {{ content.product.min_order2 }} {{ produto.box_quantity }}
            {{ content.product.min_order3 }}
          </p>
          <span class="dash"></span> -->
          </div>
          <!-- END PRICE BOX -->

          <!-- STOCK -->
          <p v-if="produto.stock > 0" class="stock">
            {{ produto.stock }} {{ content.product.stock }}
          </p>
          <br />
          <br />
          <br />
          <!-- END STOCK -->

          <!-- DELIVERY -->
          <p v-if="produto.stock > 0" class="delivery">
            {{ content.product.delivery_period }} -
            {{ produto.delivery_period }}
            {{ content.product.delivery_periodII }}
          </p>
          <!-- END DELIVERY -->

          <p class="product_description" v-html="produto.description"></p>
          <div class="action">
            <a
              v-if="produto.stock > 0"
              @click="addProduct(produto)"
              class="button-fill"
              >{{ content.product.btn4 }}</a
            >
            <a v-if="produto.stock == 0" class="button-fill">{{
              content.product.btn6
            }}</a>
            <a
              v-if="produto.stock > 0"
              v-on:click="modal = 'cart'"
              class="button-outline"
              >{{ content.product.btn5 }}</a
            >
          </div>
        </div>
      </div>
      <!-- END DESCRIPTION MOBILE -->

      <!-- DESCRIPTION DESKTOP -->
      <div class="description desktop">
        <div class="left">
          <h4>{{ produto.name }}</h4>
          <p class="product_description" v-html="produto.description"></p>

          <!-- PRICE BOTTLE -->
          <!-- W/ DISCOUNT -->
          <p class="price" v-if="produto.discount > 0">
            <span class="icomoon icon-icon-bottle"></span>
            <span class="label">{{ content.product.price }} </span>
            <span class="lt">({{ produto.it }} lt) </span>
            <span class="underline"
              >{{ produto.price.toFixed(2) }}
              <span class="units"> CHF</span></span
            >
            {{ (produto.price * (1 - produto.discount)).toFixed(2) }}
            <span class="units"> CHF</span>
          </p>
          <!-- END W/ DISCOUNT -->

          <!-- W/o DISCOUNT -->
          <p class="price" v-else>
            <span class="icomoon icon-icon-bottle"></span>
            <span class="label">{{ content.product.price }} </span>
            <span class="lt">({{ produto.it }} lt) </span>
            {{ produto.price.toFixed(2) }}
            <span class="units"> CHF</span>
          </p>
          <!-- END W/o DISCOUNT -->
          <!-- END PRICE BOTTLE -->

          <!-- PRICE BOX-->
          <div class="units" v-if="produto.box_quantity > 1">
            <!-- W/ DISCOUNT -->
            <p class="price2" v-if="produto.discount > 0">
              <span class="icomoon icon-icon-box"></span>
              {{ content.product.price2 }}
              <span class="lt"
                >({{ produto.box_quantity }} x {{ produto.it }} lt)
              </span>
              <span class="underline"
                >{{ (produto.price * produto.box_quantity).toFixed(2) }}
                <span class="units">CHF</span></span
              >
              {{
                (
                  produto.price *
                  produto.box_quantity *
                  (1 - produto.discount)
                ).toFixed(2)
              }}
              <span class="units"> CHF</span>
            </p>
            <!-- END W/ DISCOUNT -->

            <!-- W/o DISCOUNT -->
            <p class="price2" v-else>
              <span class="icomoon icon-icon-box"></span>
              {{ content.product.price2 }}
              <span class="lt"
                >({{ produto.box_quantity }} x {{ produto.it }} lt)
              </span>
              {{ (produto.price * produto.box_quantity).toFixed(2) }}
              <span class="units">CHF</span>
            </p>
            <!-- W/o DISCOUNT -->

            <!-- <span class="dash"></span>
          <p class="order">
            {{ content.product.min_order }}
            {{ content.product.min_order2 }} {{ produto.box_quantity }}
            {{ content.product.min_order3 }}
          </p>
          <span class="dash"></span>
          <a
          class="suggestion"
          :href="'/product/' + produto.suggestion"
          v-if="
            produto.box_quantity > 1 &&
            produto.suggestion != 0 &&
            produto.suggestion != null
          "
          >{{ content.product.suggestion }}</a
        >
        <a
          class="suggestion"
          :href="'/product/' + produto.suggestion"
          v-if="
            produto.box_quantity == 1 &&
            produto.suggestion != 0 &&
            produto.suggestion != null
          "
          >{{ content.product.suggestion2 }}</a
        > -->
          </div>
          <!-- END PRICE BOX-->

          <!-- STOCK -->
          <p v-if="produto.stock > 0" class="stock">
            {{ produto.stock }} {{ content.product.stock }}
          </p>
          <!-- END STOCK -->

          <!-- DELIVERY -->
          <p v-if="produto.stock > 0" class="delivery">
            {{ content.product.delivery_period }} -
            {{ produto.delivery_period }}
            {{ content.product.delivery_periodII }}
          </p>
          <!-- END DELIVERY -->

          <a
            v-if="produto.stock > 0"
            @click="addProduct(produto)"
            class="button-fill"
            >{{ content.product.btn4 }}</a
          >
          <a v-if="produto.stock == 0" class="button-fill">{{
            content.product.btn6
          }}</a>

          <a
            v-if="produto.stock > 0"
            @click="addProduct(produto, true)"
            class="button-outline"
            >{{ content.product.btn5 }}</a
          >
        </div>
        <div class="wine-img"><img :src="img_url + produto.img" /></div>
      </div>
      <!-- END DESCRIPTION DESKTOP -->

      <!-- BULLETS POINTS MOBILE -->

      <div class="bullet-points mobile">
        <div>
          <ul>
            <li v-if="produto.type">
              {{ content.product.bullet_points1.type
              }}<span>{{ produto.type }}</span>
            </li>
            <li v-if="produto.year">
              {{ content.product.bullet_points1.year }}
              <span>{{ produto.year }}</span>
            </li>
            <li v-if="produto.master_region">
              {{ content.product.bullet_points1.region
              }}<span>{{ produto.master_region }}</span>
            </li>
            <li v-if="produto.sub_region">
              {{ content.product.bullet_points1.sub_region
              }}<span>{{ produto.sub_region }}</span>
            </li>
            <li v-if="produto.varieties">
              {{ content.product.bullet_points1.varieties
              }}<span>{{ produto.varieties }}</span>
            </li>
          </ul>
          <ul class="secondary-list" id="secondary-listm">
            <li v-if="produto.winery">
              {{ content.product.bullet_points2.winery
              }}<span>{{ produto.winery }}</span>
            </li>
            <li v-if="produto.oenologist">
              {{ content.product.bullet_points2.oenologist
              }}<span>{{ produto.oenologist }}</span>
            </li>
            <li v-if="produto.production_limit > 0">
              {{ content.product.bullet_points2.production_limit
              }}<span
                >{{ produto.production_limit }}
                {{ content.product.bullet_points2.production_limit2 }}</span
              >
            </li>
            <li v-if="produto.awards">
              {{ content.product.bullet_points2.awards
              }}<span>{{ produto.awards }}</span>
            </li>
            <li v-if="produto.alcohol">
              {{ content.product.bullet_points2.alcohol
              }}<span>{{ produto.alcohol }}%</span>
            </li>
            <li v-if="produto.allergens">
              {{ content.product.bullet_points2.allergens
              }}<span>{{ produto.allergens }}</span>
            </li>
            <li v-if="produto.temperature">
              {{ content.product.bullet_points2.temperature
              }}<span>{{ produto.temperature }}</span>
            </li>
            <li v-if="produto.food">
              {{ content.product.bullet_points2.food
              }}<span>{{ produto.food }}</span>
            </li>
          </ul>
          <a @click="openListM()" id="moreless"
            ><span class="icon-icon-plus"></span
            ><span id="moreless_contentm">{{ content.product.btn2 }}</span></a
          >
          <router-link to="/online_store" class="back button-fill">{{
            content.product.btn1
          }}</router-link>
        </div>
      </div>
      <div
        class="image mobile"
        :style="{
          backgroundImage:
            'url(' +
            img_url +
            encodeURIComponent(produto.img2).replace('%2F', '/') +
            ')',
        }"
      ></div>
      <!-- END BULLET POINTS MOBILE -->
    </div>
    <OurSelection @update-cart="updateCart()" />
  </section>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";
import OurSelection from "@/components/Our-selection";
// import Cart from "@/components/Cart";

export default {
  mixins: [computedMixins, methodsMixins],

  components: {
    OurSelection,
    // Cart,
  },
  props: ["backdoor"],
  data() {
    return {
      // materiaisCarrinho: [],
      item: {},
      // produto: {
      //   id: "1",
      //   name: "Humilitas Touriga Nacional",
      //   year: "2010",
      //   type: "Red",
      //   price: 50.0,
      //   discount: 0,
      //   varieties: "Touriga Nacional",
      //   img: "garrafa2.png",
      //   img2: "wine_img2.jpg",
      //   region: "Alentejo",
      //   it: "0,75",
      //   winery: "Nome da Quinta",
      //   oenologist: "Pedro Baptista",
      //   alcohol: "14%",
      //   awards: "Decanter World Wine",
      //   allergens: "Allergens",
      //   temperature: "16º",
      //   food: "Food Pairing",
      //   description:
      //     "After the success of Humanitas, we are pleased to present the latest launch of Vinha das Virtudes, the Humilitas Réserve 2015. A limited production of only 1130 bottles for the most demanding wine lovers. ",
      //   delivery_period: "3",
      //   box_quantity: 2,
      // },
      n: 0,

      // initializeEP: "products/get-single/",
    };
  },
  computed: {
    initializeEP() {
      let temp = "products/"+this.version+"get-single/"
      return temp
    },
    materiaisCarrinho() {
      this.n;
      let temp = JSON.parse(window.localStorage.getItem("cart")) || [];

      return temp;
    },
    m() {
      this.backdoor;
      return this.n;
    },
    produto() {
      let temp = {};

      if (typeof this.item != "undefined") {
        temp = this.item;
        if (typeof temp.content != "undefined") {
            
          let content = temp.content;

          if(typeof temp.content == 'string') {
            content = JSON.parse(temp.content);
          }
          let lang = this.pageLanguage.toLowerCase();
          content = content[lang];

          for (let k in content) {
            if (content[k] != "" && content[k] != undefined)
              temp[k] = content[k];
          }

          temp.content = content;
        }

        temp.price = parseFloat(temp.price);
        temp.discount = parseFloat(temp.discount);
        temp.box_quantity = parseFloat(temp.box_quantity);
      }

      return temp;
    },
    product_limit() {
      let temp = {};
      let product_limit = 0;
      temp = this.item;
      product_limit = Intl.NumberFormat().format(temp.production_limit);
      return product_limit;
    },
  },
  async created() {
    await this.initializeSingle(this.initializeEP + this.$route.params.id);

    if (typeof this.produto.id == "undefined") {
      this.$router.push("/online_store");
    }
    this.n += 1;
  },
  methods: {
    openList() {
      document.getElementById("secondary-list").classList.toggle("show");
      if (
        document.getElementById("secondary-list").className ==
        "secondary-list show"
      ) {
        document.getElementById(
          "moreless_content"
        ).innerHTML = this.content.product.btn3;
      } else {
        document.getElementById(
          "moreless_content"
        ).innerHTML = this.content.product.btn2;
      }
    },
    openListM() {
      document.getElementById("secondary-listm").classList.toggle("show");
      if (
        document.getElementById("secondary-listm").className ==
        "secondary-list show"
      ) {
        document.getElementById(
          "moreless_contentm"
        ).innerHTML = this.content.product.btn3;
      } else {
        document.getElementById(
          "moreless_contentm"
        ).innerHTML = this.content.product.btn2;
      }
    },
    addProduct: async function (produto, openCart=false) {
      event.preventDefault();
      this.n += 1;
      if ((await this.checkProdInCart(produto.id)) == true) {

        if(openCart) {
          this.changeModal('cart')
          return false
        }
        else {
          return false;
        }
      }

      produto.quantity = 1;

      if (produto.box_quantity == "1") {
        produto.quantitySelected = "bottle";
      } else {
        produto.quantitySelected = "box";
      }

      this.materiaisCarrinho.push(produto);
      let cart = this.materiaisCarrinho;

      window.localStorage.setItem("cart", JSON.stringify(cart));

      this.$emit("update-cart");

      if(openCart) {
        this.changeModal('cart')
      }
    },
    async updateCart() {
      this.n++
      this.$emit("update-cart");
    },
    async checkProdInCart(id) {
      await this.updateCart()
      let temp = await this.materiaisCarrinho.reduce(function (
        acumulador,
        valorAtual
      ) {
        acumulador.push(valorAtual.id);

        return acumulador;
      },
      []);

      return temp.indexOf(id) > -1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

a {
  text-transform: none !important;
}
.product-section {
  display: flex;
  @include responsive(0, 1024px) {
    display: block;
  }
  .bullet-points {
    display: inline-block;
    width: 20%;
    background-color: #353431;
    vertical-align: top;
    position: relative;
    z-index: 3;
    padding: 50px;
    @include responsive(0, 1200px) {
      padding: 30px 10px;
    }
    @include responsive(0, 1024px) {
      width: 100%;
      display: block;
      text-align: center;
    }
    div {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      @include responsive(0, 1024px) {
        top: 0;
        transform: translateY(0%);
      }
      ul {
        li {
          color: white;
          font-family: $font2;
          font-weight: 300;
          font-size: 14px;
          margin-bottom: 5px;
          span {
            font-weight: 500;
            margin-left: 5px;
          }
        }
        &.secondary-list {
          display: none;
        }
      }
      #moreless {
        margin: 20px 0 !important;
        color: white;
        text-align: left;
        display: block;
        @include responsive(0, 512px) {
          margin-bottom: 30px !important;
        }
        &:hover {
          color: $red;
        }
        .icon-icon-plus {
          font-family: "icomoon";
          background-color: white;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          color: $dark-grey;
          margin-right: 10px;
          font-size: 12px;
          line-height: 20px;
          display: inline-block;
          font-weight: 600;
          text-align: center;
        }
        .icon-icon-plus:before {
          content: "\e907";
        }
      }
      .button-fill {
        padding: 0 10px !important;
        @include responsive(0, 512px) {
          padding: 0 30px !important;
        }
      }
      a {
        @include responsive(0, 1024px) {
          margin: 0 auto !important;
          text-align: center !important;
        }
      }
      .back {
        // margin: 0 auto !important;
        // text-align: center !important;
        margin-top: 30px !important;
      }
    }
  }
  .image {
    display: inline-block;
    width: 35%;
    background-size: cover;
    background-position: center center;
    vertical-align: top;
    min-height: 700px;
    margin-left: -1px;
    position: relative;
    z-index: 2;
    @include responsive(0, 1400px) {
      width: 30%;
    }
    @include responsive(0, 1024px) {
      width: 100%;
      display: block;
      min-height: 0;
      height: 400px;
    }
  }
  .description {
    display: inline-block;
    width: 45%;
    vertical-align: top;
    position: relative;
    padding-top: 0px;
    @include responsive(0, 1400px) {
      width: 50%;
    }
    @include responsive(0, 1024px) {
      width: 100%;
      display: block;
      padding-bottom: 60px;
    }
    @include responsive(0, 512px) {
      padding: 30px 20px;
      text-align: center;
    }
    h4 {
      color: $dark-grey;
      text-align: center;
      margin-bottom: 10px;
    }
    .left {
      width: 70%;
      display: inline-block;
      padding: 30px;
      padding-right: 50px;
      max-width: 800px;
      @include responsive(0, 1024px) {
        width: 100%;
        padding: 0 30px;
        max-width: 100%;
      }
      @include responsive(0, 1024px){
        padding: 0 10px;
      }
      h4 {
        text-align: left;
        @include responsive(0, 512px) {
          text-align: left;
        }
      }
      a {
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
        padding: 0;
        width: 170px;
        margin-top: 30px;
        @include responsive(0, 512px) {
          margin: 10px 5px;
          width: 100%;
        }
      }
      h4 {
        color: $dark-grey;
      }
      p {
        margin-bottom: 5px !important;
        text-align: left;
        max-width: 100%;
        font-weight: 300;
        @include responsive(0, 512px) {
          max-width: 100%;
        }
        &.price {
          margin-top: 20px;
          font-size: 18px;
          .label {
            font-family: $font1;
          }
          .units {
            font-size: 14px;
            font-family: $font2;
          }
          .underline {
            color: $red;
            text-decoration: line-through;
          }
        }
        &.price2 {
          font-size: 14px;
          line-height: 20px;
          margin-top: 5px;
          .units {
            font-size: 11px;
          }
          .underline {
            color: $red;
            text-decoration: line-through;
          }
        }
        &.order {
          margin-top: 10px;
          font-size: 14px;
          font-weight: 600;
          line-height: 25px;
          span {
            font-weight: 300;
          }
        }
        &.stock {
          display: inline-block;
          font-size: 14px;
          line-height: 25px;
          padding: 5px;
          border-top: 1px solid $red;
          border-bottom: 1px solid $red;
          margin-top: 20px !important;
          @include responsive(0, 512px) {
            float: left;
          }
        }
        &.delivery {
          margin: 15px 0 0px 0;
          font-size: 14px;
          font-weight: 400;
          @include responsive(0, 1024px) {
            margin-bottom: 20px;
            margin-top: 0;
            text-align: left;
            display: block;
          }
        }
      }
      .dash {
        display: block;
        width: 200px;
        height: 1px;
        background-color: $red;
        margin-top: 10px;
      }
      .action {
        text-align: center;
      }
    }
    .wine-img {
      position: relative;
      height: 100%;
      width: 30%;
      display: inline-block;
      vertical-align: top;
      img {
        position: absolute;
        max-width: 100%;
        height: 600px;
        left: 0;
        bottom: 20px;
      }
      @include responsive(0, 1350px) {
        img {
          height: 500px;
        }
      }
      @include responsive(0, 1024px) {
        width: 100%;
        height: auto;
        text-align: center;
        margin-top: 20px;
        img {
          height: 400px;
          position: relative;
          margin: 0 auto;
        }
      }
    }
  }

  .suggestion {
    display: block !important;
    width: 100% !important;
    font-size: 14px;
    text-decoration: underline;
    margin-top: 10px;
  }
}
// img {
//   height: 30vw;
//   position: absolute;
//   bottom: 20px;
//   left: 70%;
//   z-index: 10;
//   @include responsive(0, 1024px) {
//     height: 400px;
//     bottom: 60px;
//   }
//   @include responsive(0, 512px) {
//     height: 400px;
//     position: relative;
//     bottom: auto;
//     left: auto;
//     margin-bottom: 20px;
//   }
// }

.mobile {
  display: none !important;
  @include responsive(0, 1024px) {
    display: block !important;
  }
}

.desktop {
  @include responsive(0, 1024px) {
    display: none !important;
  }
}

.our-selection {
  margin-top: 0px;
  padding-top: 80px;
}

.lt {
  font-size: 14px;
}
</style>